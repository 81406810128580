<template>
  <b-modal
    id="resetPasswords"
    title="Reset Password"
    hide-footer
  >

    <validation-observer
      ref="reset_password"
    >
      <b-form
        autocomplete="off"
        @submit.prevent="submit"
      >
        <b-form-group
          :label="t('Enter New Password')"
          label-for="password"
        >
          <b-form-input
            id="id"
            v-model="reset.adminuserid"
            hidden
          />
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="password"
            rules="required|password"
          >
            <b-form-input
              id="password"
              v-model="reset.password"
              :class="errors[0] ? 'border-red' : ''"
              type="password"
              :placeholder="t('Enter New Password')"
            />
            <small class="text-danger">{{ t(errors[0]) }}</small>

          </validation-provider>
        </b-form-group>
        <b-form-group class="text-right">
          <b-button
            variant="primary"
            type="submit"
          >
            {{ t('Submit') }}
          </b-button>
        </b-form-group>
      </b-form>

    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BModal, VBModal, BForm, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { required, password } from '@validations'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    form: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      password,
      reset: {
        adminuserid: '',
        password: '',
      },
    }
  },
  watch: {
    form(newVal) {
      this.reset.adminuserid = newVal
      this.reset.password = ''
    },
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$refs.reset_password.validate().then(success => {
        if (success) {
          store.dispatch('PasswordStore/setPassword', this.reset).then(response => {
            if (response.data.code === '200') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${response.data.msg}`,
                  icon: 'Success',
                  variant: 'success',
                  text: '',
                },
              })
              this.$bvModal.hide('resetPasswords')
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${response.data.msg}`,
                  icon: 'Danger',
                  variant: 'danger',
                  text: '',
                },
              })
            }
          }).catch(e => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${e.msg ? e.msg : 'Something Went Wrong'}`,
                icon: 'Danger',
                variant: 'danger',
                text: '',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#preview video{
  max-height: 100px;
}
#preview img{
  max-height: 100px;
}
#preview audio{
  max-height: 100px;
}
</style>
