<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

      <b-button
        variant="primary"
        @click="redirectToCreate"
      >
        Create User
      </b-button>
    </div>
    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <user-filter
        :form="form"
        @searchData="recieveData"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(adminuserid)="data">
          <span class="">{{ data.item.adminuserid }}</span>
        </template>
        <template #cell(username)="data">
          <span class="">{{ data.item.username }}</span>
        </template>
        <template #cell(email)="data">
          <span class="">{{ data.item.email }}</span>
        </template>
        <template #cell(firstname)="data">
          <span class="">{{ data.item.firstname.charAt(0).toUpperCase() + data.item.firstname.slice(1) }}</span>
        </template>
        <template #cell(lastname)="data">
          <span class="">{{ data.item.lastname.charAt(0).toUpperCase() + data.item.lastname.slice(1) }}</span>
        </template>
        <template #cell(cityname)="data">
          <span class="">{{ data.item.city_name }}</span>
        </template>
        <template #cell(usertype)="data">
          <span class="">{{ data.item.usertype }}</span>
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status === constants.USER_STATUS_ACTIVE"
            class="text-success"
          >Active</span>
          <span
            v-if="data.item.status === constants.USER_STATUS_INACTIVE"
            class="text-danger"
          >In-Active</span>
        </template>
        <template
          #cell(reset_password)="data"
          :class="text-center"
        >
          <feather-icon
            icon="RefreshCwIcon"
            @click.prevent="resetPassword(data.item.adminuserid)"
          />
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">

        <b-col
          lg="4"
          class="py-2 d-flex"
        >
          <span class="pr-2 pt-10 align-self-center">
            {{ t('Total Entries') }} {{ items.total }}
          </span>

          <b-form-group class="mb-0 align-self-center">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getUsers"
            />
          </b-form-group>

        </b-col>
        <b-col lg="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>
    <reset-password
      ref="resetPasswords"
      :form="resetPasswordId"
    />
    <vue-context
      ref="UserContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
       <!-- && checkPermission(permissions.EDIT_USER) -->
        <b-link
          v-if="data.text == 'Edit User'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-else-if="data.text != 'Edit User'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BTable, BCard, BButton, BLink, BRow, BCol, BCollapse, VBToggle, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
import constants from '@/constants'
// eslint-disable-next-line import/no-unresolved
import ResetPassword from './ResetPasswords.vue'
import checkRoute from '@/utils/chechR'
import UserFilter from './UserFilter.vue'
import { tableFilter, showLoader, hideLoader } from '@/utils/common'
import checkPermissions from '@/utils/checkPermissons'
import permissions from '@/permissions'

export default {
  name: 'UserList',
  components: {
    BLink,
    BCol,
    BRow,
    BCard,
    BButton,
    BTable,
    VueContext,
    ResetPassword,
    UserFilter,
    BCollapse,
    BFormSelect,
    BFormGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t, permissions }
  },
  data() {
    return {
      tableFilter,
      constants,
      items: '',
      resetPasswordId: 0,
      form: {
        params: {
          user_type: '',
          city: '',
          status: '',
          email: '',
          name: '',
          username: '',
        },
        size: 10,
        totalElements: '',
        totalPages: '',
        sortBy: 'adminuserid',
        sortOrder: 'desc',
      },
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'adminuserid', label: this.$i18n.t('Id'), sortable: true },
        { key: 'username', label: this.$i18n.t('Username'), sortable: true },
        { key: 'email', label: this.$i18n.t('Email'), sortable: true },
        { key: 'firstname', label: this.$i18n.t('FirstName'), sortable: true },
        { key: 'lastname', label: this.$i18n.t('LastName'), sortable: true },
        { key: 'cityname', label: this.$i18n.t('City'), sortable: true },
        { key: 'usertype', label: this.$i18n.t('Usertype'), sortable: true },
        { key: 'status', label: this.$i18n.t('Status'), sortable: true },
        { key: 'reset_password', label: this.$i18n.t('Reset Password'), sortable: false },
      ]
    },
    menuData() {
      return [
        { id: 1, icon: 'EditIcon', text: this.$i18n.t('Edit User') },
      ]
    },
  },
  watch: {
    items() {
      return this.items
    },
  },
  mounted() {
    this.getUsers()
  },
  created() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
    checkPermission(id) {
      return checkPermissions(id)
    },
    recieveData(val) {
      this.items = val
    },
    resetPassword(id) {
      this.resetPasswordId = id
      this.$bvModal.show('resetPasswords')
    },
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getUsers()
    },
    check(val) {
      return checkRoute(val)
    },
    async getUsers() {
      showLoader()
      await store.dispatch('companyStore/getUserList', this.form).then(response => {
        if (response.data.code === '200') {
          this.items = response.data.data
        }
      }).catch(e => {
      // eslint-disable-next-line eqeqeq
        if (e.msg == 'Unauthenticated.') {
          this.$router.back()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No Permission',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
      hideLoader()
    },
    getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      axios.post(`${constants.WEB_API_PREFIX}/admin/user/list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
        })
      hideLoader()
    },
    redirectToCreate() {
      this.$router.push({ name: 'create-users' })
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.values = item
      this.$refs.UserContextMenu.open(event, item)
    },
    optionClicked(id) {
      // eslint-disable-next-line eqeqeq
      if (id == 1) {
        this.$root.$router.push(`/admin/user/edit-user/${this.values.encode_id}`)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';

@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
