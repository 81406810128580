<template>
  <b-form
    autocomplete="off"
    @submit.prevent="filterUser"
  >
    <b-row class="mb-2">
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="username"
              v-model="form.params.username"
              :placeholder="t('Username')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="name"
              v-model="form.params.name"
              :placeholder="t('Name')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="email"
              v-model="form.params.email"
              :placeholder="t('Email')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group>
          <v-select
            v-model="form.params.status"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :reduce="op => op.value"
            :placeholder="t('Status')"
            :options="status"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              ref="city_name"
              v-model="city_name"
              :suggestions="cityOptions"
              :input-props="{id: 'autosuggest__input_ajax',
                             placeholder: t('City'),
                             class: 'form-control',}"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="renderSuggestion"
              @selected="change_autosuggest"
              @input="suggestForm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <v-select
            v-model="form.params.user_type"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="group_desc"
            :reduce="op => op.group_id"
            :placeholder="t('User Type')"
            :options="userTypeOptions"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="success mx-1"
          type="submit"
        >
          {{ t('Search') }}
        </b-button>
        <b-button
          variant="danger mx-1"
          @click="resetFilter"
        >
          {{ t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BRow, BCol, BInputGroup, BFormGroup, BButton, BForm, BFormInput,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { status } from '@/utils/common'

export default {
  components: {
    BForm,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    BInputGroup,
    BFormGroup,
    BButton,
    VueAutosuggest,
  },
  props: ['form'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      cityOptions: [],
      userTypeOptions: [],
      city_name: '',
      status,
    }
  },
  mounted() {
    store.dispatch('userManage/getUserType').then(response => {
      if (response.data.code === '200') {
        this.userTypeOptions = response.data.data
      }
    })
  },
  methods: {
    SearchLeadData() {
      this.$emit('searchData', this.form)
    },
    suggestForm(val) {
      if (val.length >= 3) {
        store.dispatch('getCountryList/getCityAuto', { city: val }).then(response => {
          if (response.data.code === '200') {
            this.cityOptions.splice(0, this.cityOptions.length)
            this.cityOptions.push({ data: response.data.data })
            this.selected = null
          }
        })
      }
    },
    resetFilter() {
      this.form = {
        params: {
          user_type: '',
          city: '',
          status: '',
          email: '',
          name: '',
          username: '',
        },
        size: 10,
        totalElements: 0,
        totalPages: 1,
        sortBy: '',
        sortOrder: '',
      }
    },
    filterUser() {
      document.getElementById('loading-bg').classList.add('loading-bg')
      store.dispatch('companyStore/getUserList', this.form).then(response => {
        if (response.data.code === '200') {
          this.$emit('searchData', response.data.data)
          document.getElementById('loading-bg').classList.remove('loading-bg')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          document.getElementById('loading-bg').classList.remove('loading-bg')
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Somethig Went Wrong',
            icon: 'Danger',
            variant: 'danger',
            text: error,
          },
        })
        document.getElementById('loading-bg').classList.remove('loading-bg')
      })
    },
    change_autosuggest(val) {
      this.form.params.city = val.item.location_id
      this.city_name = val.item.city
      this.$emit('getCreateUser', this.form)
    },
    renderSuggestion(suggestion) {
      return suggestion.item.city
    },
  },

}
</script>

<style lang="scss">

</style>
